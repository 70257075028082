<template>
	<div>
		<brand-logo />
		<div class="small-width">
			<form @submit.prevent="checkForm">
				<MaterialInput
					v-model="form.password.value"
					name="password"
					:error="form.password.error"
					type="password"
					:label="$t('update-password.new-password')"
				/>

				<MaterialInput
					v-model="form.password_confirmation.value"
					name="password_confirmation"
					:error="form.password_confirmation.error"
					type="password"
					:label="$t('update-password.repeat-password')"
				/>

				<FormError :error="error" />

				<CButton type="submit" primary :loading="loading" :success="success">
					{{ $t('global.update') }}
				</CButton>
			</form>
		</div>
	</div>
</template>

<script>
import MaterialInput from '@/components/material-input/MaterialInput';
import CButton from '@/shared/cbutton/CButton';
import FormError from '@/shared/forms/FormError';
import apiClient from '../../api';

const brandLogo = () => import('../../shared/brand-logo/BrandLogo');

export default {
	name: 'ResetPasswordForm',
	components: {
		FormError,
		CButton,
		MaterialInput,
		brandLogo,
	},
	data() {
		return {
			success: null,
			loading: false,
			error: null,
			form: {
				password: {
					value: '',
					error: '',
				},
				password_confirmation: {
					value: '',
					error: '',
				},
			},
		};
	},
	watch: {
		success(value) {
			if (value) {
				setTimeout(() => this.$router.push('/'), 1000);
			}
		},
	},
	methods: {
		checkForm() {
			this.reset();

			const hasErrors = Object.entries(this.form).filter((p) => p[1].error).length !== 0;
			if (!hasErrors) {
				this.submitForm();
			}
		},
		reset() {
			for (const param in this.form) {
				this.form[param].error = '';
			}
		},
		async submitForm() {
			this.loading = true;
			const { form } = this;
			try {
				await apiClient.resetPassword(
					this.$route.query.resetToken,
					form.password.value,
					form.password_confirmation.value
				);
				this.success = true;
			} catch ({ response }) {
				const errorsList = [];
				if (response.data.errors) {
					for (const error of response.data.errors) {
						const { param, msg } = error;
						form[param].error = msg;
						errorsList.push({ param, msg });
					}
				}
				if (response.data.error) {
					this.error = response.data.error;
				}
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
